<template>
	<div>
		<h4 class="mb-4">{{ title }}</h4>
		<CustomTable
			ref="table"
			id_table="horse_actes"
			:transformer="['ActeTransformer', 'monte']"
			:base-filters="filters"
			primaryKey="actes_id"
			:useTransformerSort="true"
			:busy.sync="table_busy"
			:display_action_button="!horseFicheReadOnly"
			:checkboxes="!horseFicheReadOnly"
			:buttonActionEventOnly="true"
			:uniqueEventId="$options.name"
		/>
    </div>
</template>


<script type="text/javascript">
    import { EventBus } from 'EventBus'
    import CustomTable from "GroomyRoot/components/Table/CustomTable";
    import ActesMixin from "@/mixins/Actes.js";
    import Navigation from "@/mixins/Navigation.js";
    import Shutter from '@/mixins/Shutter.js'
	import MonteShutters from '@/mixins/shutters-manager/Monte.js'

    export default {
        name: "ActeJument",
        mixins: [
			ActesMixin,
            Navigation,
            Shutter,
            MonteShutters
        ],
        props: {
            horse_id: { type: Number, default: null },
            horse_nom: { type: String, default: null }
        },
    	data() {
			return {
				table_busy: true,

				events_tab: {
					'TableAction::DeleteActes::ActeJument': this.deleteActePostConfirmation,
					'TableAction::acteAjout::ActeJument'  : this.openActShutter,
				},

				last_operation: '',
				last_row_id: 0,

				actes_types: [],
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				const actes_types_test = await this.getActesTypes('TEST')
				const actes_types_vaccin = await this.getActesTypes('VAC')

				Array.prototype.push.apply(actes_types_test, actes_types_vaccin)

				this.actes_types = actes_types_test.map(type => type.actestype_id)
			},

            openActShutter(params) {
				if(this.shutterPanel().isOpen('ajout-acte')) {
					this.shutterPanel().close('ajout-acte')
					return false
				}

				const shutter = this.monteShutters['ajout-acte']
				shutter.props.horses_ids = [this.horse_id]
				shutter.props.actes_ids = []
				if(params.acte_id) {
					shutter.props.actes_ids = [params.acte_id]
				}

				shutter.onOk = () => {
					this.shutterPanel().close('ajout-acte')
					this.$refs.table.refreshTable()
				}

				this.shutterPanel().open(shutter)
            },

            deleteActePostConfirmation(actes) {
				this.applyDeleteActes(actes)
            },

            onShuttersSubmit(result) {
				this.$refs.table.refreshTable()				
            },
        },
        computed: {
            horseFicheReadOnly() {
                return this.$store.state.horseFicheReadonly
			},
			filters() {
				const today = new Date()
				const year_ago = new Date(new Date().setDate(new Date().getDate() - 365))

				let filters = {
					horse: {
						column: 'actes_horse',
						operator: 'isEqualTo',
						value: parseInt(this.horse_id)
					},
					is_in_group: {
						column: 'actes_actestype',
						operator: 'isInList',
						value: this.actes_types
					},
					is_validated: {
						column: 'actes_actesstatut',
						operator: 'isEqualTo',
						value: 1
					},
					periode_start: {
						column: 'actes_date',
						operator: 'isGreaterOrEqualThan',
						value: year_ago
					},
					periode_end: {
						column: 'actes_date',
						operator: 'isLowerOrEqualThan',
						value: today
					},
				}

				return filters
			},

			title() {
				return this.getTrad('monte.acte_jument_nom', { name: this.horse_nom })
			}
        },
		components: {
            CustomTable,
		}
	}
</script>
